.badge + .badge {
  margin-left: 5px;
}
.toast.show {
  display: block;
  opacity: 1;
  position: fixed;
  z-index: 9999;
  top: 30px;
  right: 30px;
}