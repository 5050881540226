
    .m-b-0{
        margin-bottom: 0px !important;
    }
    .form-group label{
        color:#1d253b !important;
        font-weight: bold;
    }
    .btn-primary {
        color: #fff !important;
        background-color: #429cd6 !important;
        border-color: #429cd6 !important;
        background-image: none !important;
    }
    .btn-primary:not(:disabled):not(.disabled):active{
    color: #fff !important;
    background-color: #429cd6 !important;
    border-color: #429cd6 !important;
    background-image: none !important;
    }
    .btn-primary:hover {
        color: #fff !important;
        background-color: #429cd6 !important;
        border-color: #429cd6 !important;
        background-image: none !important;
    }

    .btn-secondary {
        color: #fff !important;
        background-color: #3a589e !important;
        border-color: #3a589e !important;
        background-image: none !important;
    }
    .btn-secondary:not(:disabled):not(.disabled):active{
    color: #fff !important;
    background-color: #3a589e !important;
    border-color: #3a589e !important;
    background-image: none !important;
    }
    .btn-secondary:hover {
        color: #fff !important;
        background-color: #3a589e !important;
        border-color: #3a589e !important;
        background-image: none !important;
    }
    
    .btn-success {
        color: #fff !important;
        background-color: #0d77b7 !important;
        border-color: #0d77b7 !important;
        background-image: none !important;
    }
    .btn-success:not(:disabled):not(.disabled):active{
    color: #fff !important;
    background-color: #0d77b7 !important;
    border-color: #0d77b7 !important;
    background-image: none !important;
    }
    .btn-success:hover {
        color: #fff !important;
        background-color: #0d77b7 !important;
        border-color: #0d77b7 !important;
        background-image: none !important;
    }

   .errClr{
    color: red !important;
   }
   .updateClr{
   width: 160px;
   height: 50px;
   text-align: start !important;
   align-content: flex-start i !important;
   animation-delay: 10sec !important;
   transition-delay: 10sec !important;
   
   }
   .btn-file {
    position: relative;
    overflow: hidden;
    background-color: #0d77b7 !important;

}
.btn-file input[type=file] {
    position: absolute;
    background-color: #0d77b7 !important;
    top: 0;
    right: 0;
    min-width: 100%;
    min-height: 100%;
    font-size: 100px;
    text-align: right;
    filter: alpha(opacity=0);
    opacity: 0;
    outline: none;   
    cursor: inherit;
    display: block;
}
.css-1rhbuit-multiValue {
    background:#2578f6 !important;
}
.css-1rhbuit-multiValue div {
color: #fff !important;
}