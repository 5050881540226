.margin-bottom0 {
    margin-bottom: 0;
}
.margin-bottom-10 {
    margin-bottom: 10px;
}
.main-panel  {
    > .content {
        padding: 78px 30px 0px 280px;
        min-height: calc(100vh - 70px); 
        @media screen and (max-width: 991px) {
            padding-left: 30px;
        }
    }
}
// login
.Login-box {
    align-items: center;
    justify-content: center;
    height: 100vh;
    .base-container {
        .footer {
            padding-bottom: 0;
            display: flex;
            width: 100%;
            justify-content: flex-end;
        }
        @media only screen and (max-width:990px) {
            form {
                .form-group {
                    input {
                        min-width: 300px;
                    }
                }
            }
        }
    }
}
// ends
.daten-field-col {
    input,select,#multiselectContainerReact {
        width: 80% !important;
        @media only screen and (max-width:1200px) {
            width: 100% !important;

        }
        
        .search-wrapper {
            border: 1px solid rgba(29, 37, 59, 0.5);
            #search_input {
                padding-left: 13px;
                font-size: 0.75rem;
                font-weight: 200;
            }
            #search_input::placeholder {
                color: #adb5bd;
                opacity: 1;
                font-weight: 300;
            }
        }
        .optionListContainer {
            li {
                input {
                    width: auto !important;
                }
            }
        }
    }
    label {
        font-weight: 500;
    }
}
.icon-button {
    width: 50px;
}
.icon-box {
    width: 22px;
    height: 22px;
    cursor: pointer;
    img {
        width: 100%
    }
}
.show-pass-icon {
    position: absolute;
}
.daten-head-col {
    label {
        font-size: 18px;
    }
}
.upload-daten-management {
    thead,tbody {
        tr {
            td,th {
                &:nth-child(3) {
                    width: 100px;
                    min-width: 170px;
                }
                &:nth-child(6) {
                    width: 100px;
                    min-width: 135px;
                }
                &:nth-child(7) {
                    width: 100px;
                    min-width: 120px;
                }
                &:nth-child(8) {
                    width: 100px;
                    min-width: 155px;
                }
            }
        }
    }
}
.daten-management-box {
    max-height: calc(100vh - 310px);
    overflow-y: auto;
}
.react-datepicker {
    display: flex;
}
@media only screen and (max-width: 990px) {
    .nav-open {
        .main-panel {
            right: 0;
            -webkit-transform: translate3d(0, 0, 0);
            -moz-transform: translate3d(0, 0, 0);
            -o-transform: translate3d(0, 0, 0);
            -ms-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); 
        } 
    }
    .toggle-logout-nav {
        display: block !important;
    }
    .daten-management-box {
        max-height: unset;
        height: auto;
    }
}
@media only screen and (max-width: 568px) {
    .sidebar {
        width: 100%;
        transform: translate3d(-100%, 0, 0);
    }
    .btn.btn-fill {
        padding: 11px 20px;
    }
    .container-fluid {
        padding: 0;
    }
}