.option {
   
}

i {
    position: relative;
    top: 50%;
    right: 1%;
    font-size: large;
}

i:hover {
    color: black;
    cursor: pointer;
}

.checkbox {
    text-align:center;
   }

  .checkbox input{
    margin: 0px 0px 0px 0px;
   }

   .checkbox label{
     margin: 0px 20px 0px 3px;
   }

    /* li:first-child{
background-color: white !important;
   } */